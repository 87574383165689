<div class="d-flex align-items-center justify-content-between formborder">
  <div class="col-md-3">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <button
    type="button"
    class="btn font-size-12 button-btn"
    (click)="onAddRole()"
  >
    <i class="fa fa-plus font-size-12" aria-label="true"></i> Add
  </button>
</div>
<div class="mt-2">
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="table-responsive mt-1">
    <mat-table
      [dataSource]="dataSource"
      matSort
      style="max-width: 100%; overflow: auto; height: 60vh"
    >
      <ng-container matColumnDef="Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Role_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Role Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Role_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Designation_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Designation
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Designation_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 70px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 70px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="OnEditRoleData(row)"
            *ngIf="
              currentUser.username.toLowerCase() == 'admin' ||
              (row.Role_Level != 0 &&
                row.Designation_Name.toLowerCase() != 'admin')
            "
            style="cursor: pointer"
            aria-label="true"
            title="Edit This Role"
          ></i>
          <i
            aria-label="true"
            class="fa fa-trash style-delete"
            (click)="OnDeleteRoleData(row)"
            *ngIf="
              row.Role_Level != 0 &&
              row.Designation_Name.toLowerCase() != 'admin'
            "
            title="Delete This Role"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewRoleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveRoleMaster()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Role Master
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 formborder">
            <div class="col-md-3 p-1">
              <span class="required-lable"> Role Name </span>
              <input
                required
                type="text"
                #refRoleName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refRoleName.invalid,
                  'alert-warning':
                    refRoleName.invalid &&
                    (refRoleName.dirty ||
                      refRoleName.touched ||
                      refRoleName.untouched)
                }"
                [(ngModel)]="RoleModel.RoleName"
                placeholder="Role Name"
                name="RoleName"
                id="RoleName"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span class="required-lable"> Designation </span>
              <select
                name="Designation"
                id="Designation"
                [(ngModel)]="RoleModel.Designation"
                class="form-control input-text-css"
                (change)="getMenuListByRole()"
                #refDesignation="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refDesignation.invalid,
                  'alert-warning':
                    refDesignation.invalid &&
                    (refDesignation.dirty ||
                      refDesignation.touched ||
                      refDesignation.untouched)
                }"
              >
                <option value="">All Designation</option>
                <option
                  *ngFor="let data of DesigDropdown"
                  [value]="data.RoleId"
                >
                  {{ data.Role_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-3 p-1">
              <span> Select Type </span>
              <select
                name="MenuType"
                id="MenuType"
                [(ngModel)]="RoleModel.MenuType"
                class="form-control input-text-css"
                (change)="getMenuListByType()"
              >
                <option value="">All Type</option>
                <option value="LMS">LMS</option>
                <option value="LOS">LOS</option>
                <option value="Accounting">Accounting</option>
                <option value="Reports">Reports</option>
                <option value="Portfolio">Portfolio</option>
              </select>
            </div>

            <div class="col-md-3 p-1 mt-2">
              <form action="" class="search-text">
                <div class="bg-light rounded rounded-pill shadow-sm">
                  <div class="input-group">
                    <input
                      type="text"
                      id="FilterInputSearch"
                      (keyup)="applyFilterDesignation($event.target.value)"
                      name="FilterInputSearch"
                      placeholder="What're you searching for?"
                      class="form-control border-0 bg-light"
                    />
                    <div class="input-group-append">
                      <button
                        id="button-addon1"
                        type="submit"
                        class="btn btn-link text-primary"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            class="table-responsive mt-1"
            style="height: 700px !important"
            *ngIf="dataSourceRole"
          >
            <mat-table [dataSource]="dataSourceRole" matSort>
              <ng-container matColumnDef="Id">
                <mat-header-cell
                  class="grid-header"
                  *matHeaderCellDef
                  style="width: 5% !important"
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  >{{ i + 1 }}</mat-cell
                >
              </ng-container>

              <ng-container matColumnDef="MM_MenuFor">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Type
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.MM_MenuFor }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="MM_Name">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Menu
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.MM_Name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Page_Name">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Name
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Page_Name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="View">
                <mat-header-cell
                  class="grid-header j-c-center"
                  *matHeaderCellDef
                >
                  View
                  <mat-checkbox
                    name="View"
                    id="View"
                    [(ngModel)]="ActionModel.View"
                    (change)="checkUncheckAll($event)"
                  ></mat-checkbox>
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell j-c-center"
                >
                  <mat-checkbox
                    class="example-margin"
                    name="View{{ i }}"
                    id="View{{ i }}"
                    [(ngModel)]="row.View"
                    (change)="onViewChange(row)"
                  ></mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Add">
                <mat-header-cell
                  class="grid-header j-c-center"
                  *matHeaderCellDef
                >
                  Add
                  <mat-checkbox
                    name="Add"
                    id="Add"
                    [(ngModel)]="ActionModel.Add"
                    (change)="checkUncheckAll($event)"
                  ></mat-checkbox>
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell j-c-center"
                >
                  <mat-checkbox
                    class="example-margin"
                    name="Add{{ i }}"
                    id="Add{{ i }}"
                    [(ngModel)]="row.Add"
                    (change)="onCheckboxChange(row)"
                  ></mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Edit">
                <mat-header-cell
                  class="grid-header j-c-center"
                  *matHeaderCellDef
                >
                  Edit
                  <mat-checkbox
                    name="Edit"
                    id="Edit"
                    [(ngModel)]="ActionModel.Edit"
                    (change)="checkUncheckAll($event)"
                  ></mat-checkbox>
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell j-c-center"
                >
                  <mat-checkbox
                    class="example-margin"
                    name="Edit{{ i }}"
                    id="Edit{{ i }}"
                    [(ngModel)]="row.Edit"
                    (change)="onCheckboxChange(row)"
                  ></mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Delete">
                <mat-header-cell
                  class="grid-header j-c-center"
                  *matHeaderCellDef
                >
                  Delete
                  <mat-checkbox
                    name="Delete"
                    id="Delete"
                    [(ngModel)]="ActionModel.Delete"
                    (change)="checkUncheckAll($event)"
                  ></mat-checkbox>
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell j-c-center"
                >
                  <mat-checkbox
                    class="example-margin"
                    name="Delete{{ i }}"
                    id="Delete{{ i }}"
                    [(ngModel)]="row.Delete"
                    (change)="onCheckboxChange(row)"
                  ></mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Print">
                <mat-header-cell
                  class="grid-header j-c-center"
                  *matHeaderCellDef
                >
                  Print &nbsp;<mat-checkbox
                    name="Print"
                    id="Print"
                    [(ngModel)]="ActionModel.Print"
                    (change)="checkUncheckAll($event)"
                  ></mat-checkbox>
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell j-c-center"
                >
                  <mat-checkbox
                    class="example-margin"
                    name="Print{{ i }}"
                    id="Print{{ i }}"
                    [(ngModel)]="row.Print"
                    [checked]="row.isSelected"
                  ></mat-checkbox>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumnsRole"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsRole"
              ></mat-row>
            </mat-table>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveRoleMaster()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            {{ BtnName }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
