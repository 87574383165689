<a href="javascript:void(0)" (click)="onOpenViewDetailModal()">
  <ng-content></ng-content>
</a>

<div
  class="modal fade in"
  id="LosSearchCustomerViewDetailModal{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div
          cdkDragHandle
          class="modal-header"
          style="padding: 2px; border: none"
        >
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Customer Information
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div style="overflow: auto">
            <div class="formborder">
              <table>
                <tbody>
                  <tr>
                    <td rowspan="4">
                      <img
                        src="{{ customerProfileBaseUrl }}{{
                          customerData.CustomerId
                        }}/{{ customerData.ProfilePic }}"
                        alt="img"
                        onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                        height="70"
                      />
                      <!-- style="width: 70px; height: 70px; border: 1px solid #dddd; border-radius: 50%;" -->
                    </td>
                    <td><strong> Name : </strong></td>
                    <td><strong> Relation </strong></td>
                    <td><strong> Gender, Age </strong></td>
                    <td><strong> Phone No: </strong></td>
                    <td><strong> Email: </strong></td>
                    <!--<td><strong> Occupation: </strong></td>-->
                    <td><strong> Last Update On: </strong></td>
                  </tr>
                  <tr>
                    <td>{{ customerData.CustomerName }}</td>
                    <td>{{ customerData.RelationName }}</td>
                    <td>{{ customerData.Gender }}, {{ customerData.Age }}</td>
                    <td *ngIf="currentUser.IsMaskingMobile">
                      {{ customerData.PhoneNo | mask }}
                    </td>
                    <td *ngIf="!currentUser.IsMaskingMobile">
                      {{ customerData.PhoneNo }}
                    </td>
                    <td>{{ customerData.Email }}</td>
                    <!--<td>{{ customerData.Occupation }}</td>-->
                    <td>{{ customerData.CreateOn }}</td>
                  </tr>

                  <tr>
                    <td><strong>Religion</strong></td>
                    <td><strong>Cast</strong></td>
                    <td><strong>MaritalStatus</strong></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{{ customerData.Religion }}</td>
                    <td>{{ customerData.Cast }}</td>
                    <td>{{ customerData.MaritalStatus }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- address list -->
            <div class="mt-3 formborder">
              <mat-table
                [dataSource]="dataSourceAddress"
                #SortAddress="matSort"
                matSort
              >
                <ng-container matColumnDef="AddressId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw50"
                    style="max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="AddressType">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Address Type</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    (click)="onOpenAddressMapModal(row)"
                    style="
                      color: blue;
                      text-decoration-line: underline;
                      cursor: pointer;
                    "
                  >
                    {{ row.AddressType }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Address">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw300"
                    *matHeaderCellDef
                    >Address</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw300">{{
                    row.Address
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="LandMark">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >LandMark</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">{{
                    row.LandMark
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Tehsil_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Tehsil</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Tehsil_Name
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="District_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >District</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.District_Name
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="PinCode">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                    >PinCode</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                    row.PinCode
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="AddressRentBuy">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw150"
                    *matHeaderCellDef
                    >Rent/Own</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw150">{{
                    row.AddressRentBuy
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="TotalYearsOnAddress">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >How many years</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.TotalYearsOnAddress
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="IsCommunicationAddress">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Comm. Address</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.IsCommunicationAddress
                  }}</mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsAddress"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsAddress"
                ></mat-row>
              </mat-table>
            </div>

            <!-- Categoy list -->
            <div class="mt-3 formborder">
              <mat-table
                [dataSource]="dataSourceCategory"
                #CategorySort="matSort"
                matSort
              >
                <ng-container matColumnDef="CategoyId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Categoy">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Categoy</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Categoy
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Doc_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Document Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <img
                      src="assets/images/aadhar.jpg"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="row.Doc_Name == 'Aadhaar Card'"
                    />
                    <img
                      src="assets/images/dl.jpg"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="row.Doc_Name == 'Driving License'"
                    />
                    <img
                      src="assets/images/electricity.jpg"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="row.Doc_Name == 'Electricity Bill'"
                    />
                    <img
                      src="assets/images/emp-id-card.jpg"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="row.Doc_Name == 'Other Photo ID'"
                    />
                    <img
                      src="assets/images/pan-card.jpg"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="row.Doc_Name == 'PAN Card'"
                    />
                    <img
                      src="assets/images/passport.jpg"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="row.Doc_Name == 'Passport Copy'"
                    />
                    <img
                      src="assets/images/rasan-card.jpg"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="row.Doc_Name == 'Ration Card'"
                    />
                    <img
                      src="assets/images/voter-id.jpg"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="row.Doc_Name == 'Voter ID Card'"
                    />
                    <img
                      src="assets/images/doc.png"
                      alt=""
                      style="max-width: 50px; margin-right: 5px"
                      *ngIf="
                        row.Doc_Name !== 'Aadhaar Card' &&
                        row.Doc_Name !== 'Driving License' &&
                        row.Doc_Name !== 'Electricity Bill' &&
                        row.Doc_Name !== 'Voter ID Card' &&
                        row.Doc_Name !== 'Ration Card' &&
                        row.Doc_Name !== 'Passport Copy' &&
                        row.Doc_Name !== 'PAN Card' &&
                        row.Doc_Name !== 'Other Photo ID'
                      "
                    />
                    {{ row.Doc_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="KYC_DocNumber">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Document Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <div *ngIf="currentUser.IsMaskingMobile">
                      {{ row.KYC_DocNumber | mask }}
                    </div>
                    <div *ngIf="!currentUser.IsMaskingMobile">
                      {{ row.KYC_DocNumber }}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="UploadOn">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Uploaded On</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.UploadOn
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="EditAction">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 70px; justify-content: center"
                    *matHeaderCellDef
                  >
                    View
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 70px; justify-content: left"
                  >
                    <a
                      href="{{ customerProfileBaseUrl }}{{
                        customerData.CustomerId
                      }}/{{ row.KYC_DocFile }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="row.Doc_FileName"
                    >
                      <i
                        class="fa fa-eye"
                        style="
                          font-size: medium;
                          cursor: pointer;
                          text-align: center;
                        "
                      ></i>
                    </a>
                    <a
                      href="{{ customerProfileBaseUrl }}{{
                        customerData.CustomerId
                      }}/{{ row.KYC_DocFile1 }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="row.KYC_DocFile1"
                    >
                      <i
                        class="fa fa-eye"
                        style="
                          font-size: medium;
                          cursor: pointer;
                          text-align: center;
                        "
                      ></i>
                    </a>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsCategory"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsCategory"
                ></mat-row>
              </mat-table>
            </div>

            <!-- application list -->
            <div class="mt-3 formborder">
              <mat-table
                [dataSource]="dataSourceApplication"
                #ApplicationSort="matSort"
                matSort
              >
                <ng-container matColumnDef="ApplicationId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                  >
                    #
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ApplicationNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Application No</mat-header-cell
                  >
                  <!-- <mat-cell *matCellDef="let row" class="grid-cell" (click)="onOpenApplicationMapModal(row)"
      style="color: blue; text-decoration-line: underline; cursor: pointer;">
      {{ row.ApplicationNo }}
    </mat-cell> -->
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.ApplicationNo
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Loan A/C No.
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.LoanAcNo
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Total_OverDUE_EMI_Amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >OverDUE EMI Amount
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Total_OverDUE_EMI_Amount
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Balance_Principle">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Principle</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Balance_Principle
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="CustomerType">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Customer Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{
                      row.CustomerType == "Hirer"
                        ? "Borrower"
                        : row.CustomerType
                    }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Product">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Product</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Product
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Branch">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Branch</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Branch
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="LoanAmount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{
                      row.LoanAmount > 0 ? "₹" + row.LoanAmount : ""
                    }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="CreateOn">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Application Create On</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CreateOn
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Application_Status">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell"
                    >{{ row.Application_Type }}-{{
                      row.LoanAcNo != "" &&
                      row.LoanAcNo != null &&
                      row.Status == "Completed"
                        ? "Live"
                        : row.Status
                    }}</mat-cell
                  >
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsApplication"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsApplication"
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="LosSearchCustomerViewMapModal{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 5px">
          <p class="modal-title" id="myModalLabel" name="myModalLabel">
            Address: {{ ShowMapAddress }}
          </p>
          <button
            type="button"
            (click)="onCloseAddressMapModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><i class="far text-black fa-times-circle"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div id="map" style="border: 2px solid #3872ac">
            <agm-map
              [latitude]="lat"
              [longitude]="lng"
              [zoom]="zoom"
              [disableDefaultUI]="true"
              [zoomControl]="true"
            >
              <agm-marker [latitude]="lat" [longitude]="lng"> </agm-marker>
            </agm-map>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
